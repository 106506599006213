
.vertical {
    writing-mode: vertical-lr;
}

.table {
  margin-left: auto;
  margin-right: auto;
}

.fade-in {
  opacity: 1;
  transition: all 250ms linear;
}

.fade-out-2 {
  opacity: 0;
  transition: all 250ms linear 2s;
}
.fade-out-5 {
  opacity: 0;
  transition: all 250ms linear 5s;
}
.fade-out-10 {
  opacity: 0;
  transition: all 250ms linear 10s;
}